

export function newTotalInputs(user_edit_dictionary, generated_dictionary, form_fields) {
    const truthDictionary = {}
    

    for (const key in form_fields) {
        if (key === "field_order" || (!(key in user_edit_dictionary) && !(key in generated_dictionary))) {
            if (form_fields[key]['type'] === "list") {
                truthDictionary[key] = [];
            } else if (form_fields[key]["type"] === "boolean") {
                truthDictionary[key] = false;
            } else if (form_fields[key]["type"] === "dictionary") {
                truthDictionary[key] = {};
            } else {
                truthDictionary[key] = undefined;
            }
            continue;
        }

        if (!(key in generated_dictionary) && (key in user_edit_dictionary)) {
            truthDictionary[key] = user_edit_dictionary[key]
        } else if ((key in generated_dictionary) && !(key in user_edit_dictionary)) {
            truthDictionary[key] = generated_dictionary[key]
        } else {
            if (form_fields[key]['type'] === 'boolean') {
                if (user_edit_dictionary[key] === false) {
                    truthDictionary[key] = generated_dictionary[key]
                } else {
                    truthDictionary[key] = user_edit_dictionary[key]
                }
            } else if (form_fields[key]['type'] === 'list') {
                if (user_edit_dictionary[key].length >= 1) {
                    truthDictionary[key] = user_edit_dictionary[key]
                } else {
                    truthDictionary[key] = generated_dictionary[key]
                }
            } else if (form_fields[key]['type'] === 'dictionary') {
                if (Object.keys(user_edit_dictionary[key]).length >= 1) {
                    truthDictionary[key] = user_edit_dictionary[key]
                } else {
                    truthDictionary[key] = generated_dictionary[key]
                }
            } else {
                if (!(user_edit_dictionary[key]) || user_edit_dictionary[key] === undefined || user_edit_dictionary[key].length <= 0) {
                    truthDictionary[key] = generated_dictionary[key]
                } else {
                    truthDictionary[key] = user_edit_dictionary[key]
                }
            }
        }
    }

    return truthDictionary

}

export function giveBackUserViewVisit(visit) {
    const newVisit = {}
    const sections = ['call_info', 'treatment_info', 'vitals_info'];

    for (let section of sections) {
        newVisit[section] = {
            ...visit[section].generated_result.value,
            ...visit[section].user_edit.value,
        }
    }

    return newVisit

}